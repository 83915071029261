import React from "react";
import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";

import Input from "./Input";
import Button from "./Button";

type Inputs = {
  "bot-field": string;
  "form-name": string;
  "first-name": string;
  "last-name": string;
  phone: string;
  "phone-confirmation": string;
  email: string;
  message: string;
  marketing: boolean;
};

const ContactForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const searchParams = new URLSearchParams({
      ...data,
      marketing: String(data.marketing),
    });

    axios("/", {
      method: "POST",
      data: searchParams,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then(() => {
        reset();
        toast(
          `Thanks for contacting us! We will get in touch with you shortly.`,
          {
            type: "success",
          }
        );
        window?.posthog?.capture("ContactForm.submitted");
      })
      .catch(() => {
        toast(`An error occured while submitting. Please try again.`, {
          type: "error",
        });
      });
  };

  return (
    <form
      name="contact"
      data-netlify="true"
      netlify-honeypot="bot-field"
      onSubmit={handleSubmit(onSubmit)}
      className="mb-16"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" {...register("form-name", { value: "contact" })} />
      <div className="md:grid md:grid-cols-2 md:gap-y-2 md:gap-x-12">
        <Input
          type="text"
          label="First Name"
          placeholder="Bill"
          errors={errors}
          {...register("first-name", {
            required: "First name is required",
          })}
        />
        <Input
          type="text"
          label="Last Name"
          placeholder="Murray"
          errors={errors}
          {...register("last-name", {
            required: "Last name is required",
          })}
        />
        <Input
          type="tel"
          label="Phone Number"
          placeholder="123-456-7890"
          errors={errors}
          {...register("phone", {
            required: "Phone number is required",
          })}
        />
        <Input
          type="tel"
          label="Confirm Phone Number"
          placeholder="123-456-7890"
          errors={errors}
          {...register("phone-confirmation", {
            required: "Phone number confirmation is required",
            validate: (value) =>
              value === watch("phone") || "Phone numbers must match",
          })}
        />
        <Input
          type="email"
          label="Email Address"
          placeholder="bill@themurray.com"
          errors={errors}
          {...register("email", { required: "Email is required" })}
        />
        <Input
          type="textarea"
          label="Message"
          className="md:col-span-2"
          placeholder="Say something..."
          errors={errors}
          rows={2}
          {...register("message", {
            required: "Message is required",
          })}
        />
        <Input
          type="checkbox"
          label={
            <>
              Contact me about new listings and other news{" "}
              <em className="text-xs text-gray-400 font-normal">- Optional</em>
            </>
          }
          className="md:flex md:items-center mb-8"
          errors={errors}
          {...register("marketing", { value: false })}
        />
      </div>
      <Button className="w-full" size="large" type="submit">
        Submit
      </Button>
    </form>
  );
};

export default ContactForm;
