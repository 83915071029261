import React from "react";

import routes from "../../clhbid-routes";

import { ContactPage as seo } from "../helpers/seo";

import SEO from "../components/SEO";
import Button from "../components/Button";
import { CLHbid } from "../components/Shortcodes";
import ContactForm from "../components/ContactForm";
import SocialIcons from "../components/SocialIcons";
import ContactInformation from "../components/ContactInformation";

const ContactPage: React.FC = () => (
  <div>
    <SEO title={seo.title} description={seo.description} />
    <div className="bg-clhbid-orange">
      <h2 className="py-8 px-3 mx-auto max-w-screen-xl">Contact Us</h2>
    </div>
    <div className="py-8 px-3 mx-auto max-w-screen-xl lg:flex lg:gap-24 lg:flex-row">
      <div className="lg:w-3/4">
        <div className="w-full flex flex-col md:flex-row">
          <div className="md:max-w-lg">
            <ContactInformation className="block md:hidden" />
            <p className="italic">
              Selling farm land? It’s all we do.{" "}
              <span className="hidden md:inline">
                At <CLHbid />, we have the right platform supported by a team
                with extensive knowledge in the areas of agriculture, legal and
                accounting. We know there is a big difference between selling
                land and maximizing value for our Sellers.
                <br />
                <br />
              </span>
              Contact <CLHbid /> and let our team maximize the value for your
              lifetime of work.
              <br />
            </p>
            <p className="font-medium">~ Roy Carter, CEO</p>
          </div>
          <div className="hidden md:flex md:flex-col md:pl-16">
            <div className="mb-6">
              <img
                className="md:w-40 lg:w-48"
                alt="Roy Carter headshot"
                src="/headshots/Roy_WebHeadshot.jpg"
              />
              <p className="text-center mb-0 text-sm">Roy Carter - CEO</p>
            </div>
            <div className="mb-6">
              <img
                className="md:w-40 lg:w-48"
                alt="Bridget Hennigar headshot"
                src="/headshots/Bridget_WebHeadshot.jpg"
              />
              <p className="text-center mb-0 text-sm">Bridget Hennigar - COO</p>
            </div>
          </div>
        </div>
        <ContactForm />
      </div>
      <div className="lg:w-1/4">
        <h3 className="text-2xl mb-8">Contact Information</h3>
        <ContactInformation />
        <SocialIcons />
        <h3 className="text-2xl mt-12 mb-6">Register To Bid</h3>
        <Button external href={routes.register()} className="w-full mb-8">
          Create A Free Account
        </Button>
      </div>
    </div>
  </div>
);

export default ContactPage;
